<template>
  <div>
    <el-col :span="11">
      <el-table ref="tbList" v-loading="tableLoading" :data="tableData" highlight-current-row @row-click="rowClick">
        <el-table-column label="设备类型" prop="dataValue"></el-table-column>
      </el-table>
      <el-pagination @current-change="getList" background :total="total" :page-size="pageSize" :current-page.sync="currentPage"></el-pagination>
    </el-col>

    <el-col :span="12" :offset="1">
      <el-card>
        <div slot="header">
          {{currentDeviceType}}
        </div>
        <el-row v-for="(item,index) in versionList" :key="index" style="margin-bottom:20px;">
          <el-col :span="8">
            <el-radio v-model="currentVersion" @change="handleChange" :label="item.version" border>{{item.version}}</el-radio>
          </el-col>
          <el-col :span="16">
            <el-input label="备注" v-model="item.remark" @change="handleInputChange(index)"><template #prepend>备注</template></el-input>
          </el-col>
          <el-col :span="7">

          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      total: 0,
      tableData: [],
      tableLoading: false,
      currentDeviceType: "",
      versionList: [],
      currentVersion: "",
    };
  },
  methods: {
    getList() {
      var that = this;
      that.tableLoading = true;
      this.axios
        .post("Base_SystemBaseData/GetList", {
          DataType: "设备类型",
          pageSize: that.pageSize,
          currentPage: that.currentPage,
          total: that.total,
        })
        .then(function (response) {
          that.tableData = response.data.data.data;
          that.total = response.data.data.total;
          that.tableLoading = false;
        });
    },
    rowClick(row) {
      let that = this;
      that.currentDeviceType = "";
      that.currentVersion = "";
      that.versionList = [];
      that.axios
        .post("DeviceUpgradeFile/GetUpgradeFileList", {
          onlyText: row.dataValue,
        })
        .then(function (response) {
          if (response.data.code == 101) {
            that.currentDeviceType = row.dataValue;
            that.currentVersion = response.data.data.currentVersion;
            that.versionList = response.data.data.versionList;
          } else {
            that.currentDeviceType = response.data.msg;
          }
        });
    },
    handleChange(v) {
      let that = this;
      that.axios
        .post("DeviceUpgradeFile/UpdateCurrentVersion", {
          deviceType: that.currentDeviceType,
          version: v,
        })
        .then(function (response) {
          if (response.data.code == 101) {
            that.$message.success("切换成功");
          } else {
            that.$message.error(response.data.msg);
          }
        });
    },
    handleInputChange(i) {
      let that = this;
      that.axios
        .post("DeviceUpgradeFile/UpdateRemark", {
          deviceType: that.currentDeviceType,
          version: that.versionList[i].version,
          remark: that.versionList[i].remark,
        })
        .then(function (response) {
          if (response.data.code == 101) {
            that.$message.success("保存成功");
          } else {
            that.$message.error(response.data.msg);
          }
        });
    },
  },

  mounted() {
    //加载数据类型
    var that = this;
    that.getList();
  },
};
</script>

<style scoped>
</style>
